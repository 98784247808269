$primaryColor1: #313131;
$primaryColor2: #f58350;
$primaryColor3: #c7c3c1;
$primaryColor4: #fcf9f4;
$primaryColor5: mix($primaryColor1, #fff, 50%);
$primaryColor6: mix($primaryColor2, #fff, 50%);
$primaryColor7: mix($primaryColor3, #fff, 50%);
$primaryColor8: mix($primaryColor4, #fff, 50%);
$primaryColor9: mix($primaryColor2, #000, 95%);
$primaryColor10: mix($primaryColor3, #fff, 30%);
$primaryColor11: mix($primaryColor3, #fff, 95%);

$secondaryColor1: #a0cdc8;
$secondaryColor2: #385972;
$secondaryColor3: #ffa176;
$secondaryColor4: #fbd26e;
$secondaryColor5: #fff3;
$secondaryColor6: mix($secondaryColor1, #fff, 50%);
$secondaryColor7: mix($secondaryColor2, #fff, 50%);

$greyColor1: #c7c3c1;

$primaryButtonBgColor: $primaryColor2;
$primaryButtonColor: $primaryColor4;
$primaryToggleButtonBgColor: $primaryColor2;
$dashedAreaBorderColor: $primaryColor2;
$primaryTextColor: $primaryColor2;
$stepsSegmentBgColor: $primaryColor2;
$contractCurrentPhaseBgColor: $primaryColor2;
$sidebarNavLinkActive: $secondaryColor3;
$selectInputBorderColor: $primaryColor9;
$warningTextColor: $secondaryColor4;
$faqCardBorderColor: $secondaryColor3;
$contractsTableTextColor: $primaryColor4;
$spouseCardBgColor: $primaryColor4;
$spouseCardBorderColor: $primaryColor7;
$selectOptionChecked: $primaryColor4;
$publicEstimatorTextColor: $primaryColor1;
$contractStepsTextColor: $primaryColor4;
$sidebarLogoLineColor: $primaryColor2;
$sidebarLinksTextColor: $primaryColor4;
$contractSidebarAccordionBgColor: $primaryColor4;
$contractSidebarAccordionTextColor: $primaryColor4;
$contractPdfContainerBorderColor: $primaryColor1;
$sidebarFooterBgColor: $primaryColor1;
$contractSidebarNextPhaseBgColor: $primaryColor1;
$estimatorControlBgColor: $primaryColor10;
$sidebarNavLinkBgColor: $secondaryColor5;
$contractSidebarAccordionNumbersBgColor: $primaryColor11;
$estimatorHrColor: $primaryColor3;
$contractStepsBgColor: $primaryColor11;
$contractSidebarAccordionBorderColor: $primaryColor8;
$publicEstimatorBgColor: $primaryColor4;
