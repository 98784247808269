@use "../../color";

.faq-card-header {
  background-color: transparent;
  border-top: 1px solid color.$faqCardBorderColor;

  &:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.faq-card-content {
  overflow: hidden;
  transition: max-height 250ms ease, padding 250ms ease;
}

.card-content-hide {
  max-height: 0.1px !important;
}
