@use "../../color";
@use "../../mixins";

#savvly-dashboard {
  #client-information {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .select:not(.is-multiple) {
      &:not(.is-loading)::after {
        border-color: color.$primaryButtonBgColor;
      }
    }

    .select select:focus,
    .textarea:focus,
    .input:focus,
    .select select.is-focused {
      border-color: color.$primaryButtonBgColor;
      box-shadow: 0 0 0 0 color.$primaryButtonBgColor;
    }

    .is-focused {
      &.textarea,
      &.input {
        border-color: color.$primaryButtonBgColor;
        box-shadow: 0 0 0 0 color.$primaryButtonBgColor;
      }
    }

    .select select:active,
    .textarea:active,
    .input:active,
    .select select.is-active {
      border-color: color.$primaryButtonBgColor;
      box-shadow: 0 0 0 0 color.$primaryButtonBgColor;
    }

    .is-active {
      &.textarea,
      &.input {
        border-color: color.$primaryButtonBgColor;
        box-shadow: 0 0 0 0 color.$primaryButtonBgColor;
      }
    }

    .step-content {
      .spouse-info {
        background: color.$spouseCardBgColor;
        border: 1px solid color.$spouseCardBorderColor;
        border-radius: 1rem;
      }
    }
  }

  .edit-review-button {
    font-size: 12px;
    border-radius: 10px;
  }

  .label-spacing {
    line-height: 2rem;
  }

  .drag-drop-container {
    height: 100%;
    border: 2px dashed color.$dashedAreaBorderColor;
  }

  .icon-drag-drop {
    width: 40%;
  }

  .number-list {
    width: 8%;
    margin-right: 15px;
  }

  .retry-upload-modal {
    .number-list {
      width: 6%;
    }
  }

  hr {
    background-color: color.$estimatorHrColor;
  }
}

.is-mobile-hide {
  @include mixins.desktops {
    display: block;
  }

  @include mixins.landscape-phones {
    display: none;
  }
}
