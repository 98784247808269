@use "color";

$primary: color.$primaryColor2;
$success: color.$secondaryColor1;

$warning: color.$warningTextColor;
$grey: color.$greyColor1;

$input-focus-border-color: color.$secondaryColor2;
$input-focus-color: color.$primaryColor2;

$family-sans-serif: "Roboto", sans-serif;
$family-primary: $family-sans-serif;
$family-poppins: "Poppins", sans-serif;
