@use "../../mixins";
@use "../../color";

.estimator-hiding {
  display: none;

  @include mixins.overlay(1rem);

  @include mixins.until(1200px) {
    z-index: 1;
    display: flex;
    height: fit-content;
  }
}

.my-box {
  min-height: 65vh;

  @include mixins.landscape-phones {
    min-height: 70vh;
  }

  @include mixins.until(1200px) {
    display: none !important;
  }
}

.estimator-card-container {
  height: 100%;
}

.container-height {
  height: 400px;
  min-height: 400px;
}

.slide-container {
  width: 100%;
}

.box-container {
  min-height: 40vh;
}

.gender-select {
  background: color.$estimatorControlBgColor;
  border-radius: 50px;
  padding: 10px;
}

.icon-info-estimator {
  height: 10%;
  margin-right: 5px;
  width: 4%;
}

.average-return-select {
  background: color.$estimatorControlBgColor;
  border-radius: 50px;
  padding: 10px;
}

.my-section-min-height {
  min-height: 50vh !important;
}

.lump-sum-height {
  height: 90% !important;
}

.multiple-payouts-height {
  height: 100% !important;
}

.content h1 {
  font-weight: 400;
}

.icon-gender {
  width: 47px;
}

.icon-down-arrow {
  width: 15px;
}

.card-style-background {
  width: 208px;
  background: color.$estimatorControlBgColor;
}

.modal-default-height {
  min-height: 30vh;
}

hr {
  background-color: color.$estimatorHrColor;
}

.age-slider-position {
  padding-left: 35%;
  padding-right: 35%;

  @include mixins.landscape-phones {
    padding-left: 0%;
    padding-right: 0%;
  }
}

.investment-slider-position {
  padding-left: 35%;
  padding-right: 35%;

  @include mixins.landscape-phones {
    padding-left: 0%;
    padding-right: 0%;
  }
}

.modal-content,
.modal-card {
  @include mixins.landscape-phones {
    width: 578px;
  }
}

.is-mobile-estimator {
  @include mixins.landscape-phones {
    flex-wrap: wrap;
  }
}
