@use "../../color";
@use "../../mixins";

#main-dash {
  .dash-link {
    min-height: 100%;

    .button {
      width: 100%;
    }
  }

  .meeting-dashed {
    border: 2px dashed color.$dashedAreaBorderColor;
  }

  .is-mobile-hide {
    display: block;

    @include mixins.landscape-phones {
      display: none;
    }
  }
}
