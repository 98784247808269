@use "../../color";
@use "../../mixins";

#contracts-page {
  .table-wrapper {
    #contracts-table {
      text-align: center;

      thead {
        background: color.$primaryButtonBgColor;

        th {
          color: color.$contractsTableTextColor;
          text-align: center;
        }
      }

      .no-contracts-icon {
        width: 50px;
        height: 50px;
      }

      td,
      th {
        vertical-align: middle;
      }
    }

    @include mixins.landscape-phones {
      overflow-x: auto;
    }

    .is-hoverabled {
      &:hover {
        background-color: color.$secondaryColor3;
      }
    }
  }

  .select:not(.is-multiple) {
    &:not(.is-loading)::after {
      border-color: color.$primaryButtonBgColor;
      right: 1.125rem;
    }
  }

  .has-column-border-r-styled {
    border-right-style: groove;
    border-right-width: thin;
  }
}

.viewer-container {
  border: 1px solid color.$contractPdfContainerBorderColor;
  height: 730px;
}
