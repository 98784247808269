@use "../../../color";

.steps {
  margin: 0 15% 2%;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .steps-segment {
    position: relative;

    &:not(:last-child) {
      &::after {
        content: " ";
        display: block;
        position: absolute;
      }
    }
  }

  &.is-vertical {
    .steps-segment {
      display: block;

      &:not(:last-child) {
        min-height: 4rem;
      }
    }

    &.is-short {
      .steps-segment {
        flex-grow: 0;
      }

      &.is-centered {
        justify-content: center;
      }

      &.is-bottom {
        justify-content: flex-end;
      }
    }

    &:not(.is-short) {
      height: 100%;
      display: flex;
      flex-direction: column;

      .steps-segment:not(:last-child) {
        flex-grow: 1;
      }
    }
  }

  &.is-horizontal {
    display: flex;

    .steps-segment:not(:last-child) {
      flex-basis: 1rem;
      flex-grow: 1;
    }

    &.is-narrow {
      .steps-segment {
        flex-grow: 0;

        &:not(:last-child) {
          min-width: 10rem;
        }
      }

      &.is-centered {
        justify-content: center;
      }

      &.is-right {
        justify-content: flex-end;
      }
    }

    &.has-content-centered {
      text-align: center;
    }

    &.is-balanced .steps-segment:last-child,
    &.has-content-centered .steps-segment:last-child {
      flex-basis: 1rem;
      flex-grow: 1;
    }

    &.is-narrow.has-content-centered .steps-segment:last-child {
      flex-grow: 0;
      min-width: 10rem;
    }
  }

  .steps-marker {
    border-radius: 50%;
    font-weight: 700;
    position: relative;
    z-index: 5;
    height: 2rem;
    width: 2rem;
    overflow: hidden;
  }

  font-size: 1rem;

  &.is-small {
    font-size: 0.75rem;

    .steps-marker {
      height: 1.5rem;
      width: 1.5rem;
      overflow: hidden;
    }
  }

  &.is-medium {
    font-size: 1.25rem;

    .steps-marker {
      height: 2.5rem;
      width: 2.5rem;
      overflow: hidden;
    }
  }

  &.is-large {
    font-size: 1.5rem;

    .steps-marker {
      height: 3rem;
      width: 3rem;
      overflow: hidden;
    }
  }

  &:not(.is-hollow) {
    .steps-marker:not(.is-hollow) {
      background-color: color.$stepsSegmentBgColor;
      color: color.$contractStepsTextColor;
    }

    .steps-segment.is-active {
      .steps-marker:not(.is-hollow) {
        background-color: color.$stepsSegmentBgColor;
        color: color.$contractStepsTextColor;
      }

      ~ .steps-segment .steps-marker:not(.is-hollow) {
        background-color: color.$contractStepsBgColor;
      }
    }
  }

  &.is-hollow .steps-marker {
    border: 0.3rem solid color.$stepsSegmentBgColor;
  }

  &.is-hollow .is-active .steps-marker,
  .is-active .steps-marker.is-hollow {
    border-color: color.$stepsSegmentBgColor;
  }

  &.is-hollow .steps-segment.is-active ~ .steps-segment .steps-marker {
    border-color: color.$contractStepsBgColor;
  }

  &.is-hollow .steps-marker.is-white,
  .steps-marker.is-hollow.is-white {
    border-color: color.$contractStepsTextColor;
  }

  &:not(.is-hollow) .steps-marker:not(.is-hollow).is-success {
    background-color: color.$stepsSegmentBgColor;
    color: color.$contractStepsTextColor;
  }

  &.is-hollow .steps-marker.is-success,
  .steps-marker.is-hollow.is-success {
    border-color: color.$stepsSegmentBgColor;
  }

  &.is-thin {
    .steps-marker {
      width: 0.8rem;
      height: 0.8rem;
    }

    &.is-hollow .steps-marker,
    .steps-marker.is-hollow {
      border-width: 1px;
      height: calc(0.8rem + 1px);
      width: calc(0.8rem + 1px);
    }

    &.is-vertical {
      .steps-segment:not(:last-child) {
        &::after {
          bottom: 0;
          left: calc(0.4rem - 0.5px);
          top: 0.8rem;
          width: 1px;
        }
      }

      .steps-content {
        margin-top: -1.2rem;
        margin-left: 0.8rem;
      }

      &.has-content-centered .steps-content {
        padding-top: 1.6rem;
      }
    }
  }

  &:not(.is-vertical).has-content-above .steps-segment {
    display: flex;
    flex-direction: column-reverse;

    .steps-content {
      margin-top: 0;
      padding-top: 0;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.steps-marker.is-hollow {
  border: 0.3rem solid color.$stepsSegmentBgColor;
}

.steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow {
  border-color: color.$contractStepsBgColor;
}

.steps-segment {
  &::after {
    background-color: color.$stepsSegmentBgColor;
  }

  &.is-active {
    &::after,
    ~ .steps-segment::after {
      background-color: color.$contractStepsBgColor;
    }
  }
}

@media screen and (min-width: 769px), print {
  .steps:not(.is-vertical) {
    display: flex;

    .steps-segment:not(:last-child) {
      flex-basis: 1rem;
      flex-grow: 1;
    }

    &.is-narrow {
      .steps-segment {
        flex-grow: 0;

        &:not(:last-child) {
          min-width: 10rem;
        }
      }

      &.is-centered {
        justify-content: center;
      }

      &.is-right {
        justify-content: flex-end;
      }
    }

    &.has-content-centered {
      text-align: center;
    }

    &.is-balanced .steps-segment:last-child,
    &.has-content-centered .steps-segment:last-child {
      flex-basis: 1rem;
      flex-grow: 1;
    }

    &.is-narrow.has-content-centered .steps-segment:last-child {
      flex-grow: 0;
      min-width: 10rem;
    }
  }
}

@media screen and (min-width: 769px) {
  .steps:not(.is-vertical) {
    .steps-segment:not(:last-child) {
      &::after {
        height: 1rem;
        border-radius: 20px;
        left: 0.76rem;
        right: -0.49rem;
        top: calc(1rem - (0.2rem));
      }
    }

    &.has-content-above .steps-segment:not(:last-child)::after {
      bottom: calc(1rem - (0.5rem));
      top: auto;
    }

    .extra-data {
      left: calc(50% + 1.3rem);
      right: calc(-50% + 1.3rem);
      bottom: 100%;
      position: absolute;

      &.has-overflow-right {
        overflow: visible;
        right: auto;
        white-space: nowrap;
        min-width: calc(100% - 1rem - (0.2rem));
      }

      &.has-overflow-left,
      &.has-overflow-centered {
        overflow: visible;
        left: auto;
        white-space: nowrap;
        min-width: calc(100% - 1rem - (0.2rem));
      }
    }

    &.has-content-above .extra-data {
      top: 100%;
      bottom: auto;
    }

    .steps-content {
      margin-left: 1rem;

      &:not(:last-child) {
        margin-right: -1rem;
      }

      &.is-divider-content {
        margin-right: -1rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    &.has-content-centered {
      .steps-segment:not(:last-child) {
        &::after {
          left: 50%;
          right: -50%;
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      .steps-marker {
        position: absolute;
        left: calc(50% - 1rem);
      }

      .steps-content {
        margin-top: 2rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding-top: 0.2rem;
      }
    }

    &.has-content-above.has-content-centered .steps-content {
      margin-bottom: 2rem;
      padding-bottom: 0.2rem;
    }
  }
}
