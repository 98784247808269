@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

@mixin portrait-phones {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin landscape-phones {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablets {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin desktops {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin large-desktops {
  @media (max-width: 1400px) {
    @content;
  }
}
