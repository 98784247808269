@use "../color";
@use "../mixins";

#savvly-dashboard {
  height: 100%;

  .sidebar {
    width: 15rem;
    min-height: 100vh;

    @include mixins.landscape-phones {
      font-size: 2rem;
    }

    @include mixins.desktops {
      min-height: auto;
      width: 100%;
    }
  }

  .mt-5-non-desktop {
    margin-top: 0.5rem;
    @include mixins.desktops {
      margin-top: 1.5rem;
    }
  }

  .is-fullheight {
    height: 100% !important;
  }

  .ul-fit-content {
    width: "fit-content";
  }

  .sidebar-logo {
    border-bottom: 1px solid color.$sidebarLogoLineColor;
  }

  .logout-btn {
    position: absolute;
    top: 85%;
    width: 100%;
    background-color: color.$sidebarFooterBgColor;

    &:focus,
    &:focus-within,
    &:hover,
    &.is-active {
      color: color.$sidebarLinksTextColor;
    }
  }

  a.navbar-item {
    &:focus,
    &:focus-within,
    &:hover,
    &.is-active {
      background-color: color.$sidebarFooterBgColor;
    }
  }

  .nav-links {
    .nav-link {
      z-index: 1;
      color: color.$sidebarLinksTextColor;
      transition: 0.3s ease all;

      &:hover {
        background-color: color.$sidebarNavLinkBgColor;
      }

      &.active {
        border-right: 9px solid color.$sidebarNavLinkActive;
        background-color: color.$sidebarNavLinkBgColor;
      }

      a {
        color: color.$sidebarLinksTextColor;

        &:hover {
          color: color.$sidebarLinksTextColor;
        }
      }
    }

    @include mixins.desktops {
      display: none !important;
    }
  }

  .sidebar-footer {
    background: color.$sidebarFooterBgColor;
    height: 100px;

    .footer-link {
      color: color.$sidebarLinksTextColor;
      transition: 0.3s ease-in all;

      &:hover {
        color: color.$sidebarNavLinkActive;
      }
    }

    @include mixins.desktops {
      display: none !important;
    }
  }

  .sidebar-accordion {
    .container-accordion {
      background-color: color.$contractSidebarAccordionBgColor;
      border-left: 2px solid color.$contractSidebarAccordionBorderColor;

      .current-phase {
        color: color.$contractSidebarAccordionTextColor;
        background-color: color.$contractCurrentPhaseBgColor;

        .circle-accordion {
          background-color: color.$contractSidebarAccordionBgColor;
          color: color.$contractCurrentPhaseBgColor;
        }
      }

      .current-phase-with-children {
        background-color: color.$contractSidebarAccordionBgColor;

        .circle-accordion {
          background-color: color.$contractCurrentPhaseBgColor;
          color: color.$contractSidebarAccordionTextColor;
        }
      }

      .phase-completed {
        color: color.$contractSidebarAccordionTextColor;
        background-color: color.$contractSidebarNextPhaseBgColor;

        .circle-accordion {
          background-color: color.$contractSidebarAccordionBgColor;
          color: color.$contractCurrentPhaseBgColor;
        }
      }

      .phase-pending {
        background-color: color.$contractSidebarAccordionBgColor;
        color: color.$contractSidebarAccordionNumbersBgColor;

        .circle-accordion {
          background-color: color.$contractSidebarAccordionNumbersBgColor;
          color: color.$contractSidebarAccordionTextColor;
        }
      }

      .card-content {
        background-color: color.$contractSidebarNextPhaseBgColor;
        color: color.$contractSidebarAccordionTextColor;

        &.active-card {
          border-top: 7px solid color.$sidebarNavLinkActive;
        }

        &.hidden-card {
          display: none;
        }
      }

      .circle-accordion {
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 700;
        margin: 1rem 0 1rem 2rem;
      }

      .accordion-content > div {
        padding: 10px 0;
        font-size: 14px;
        width: 100%;
      }

      .accordion-content .active {
        background-color: color.$contractCurrentPhaseBgColor;
      }
    }

    .card-header {
      background-color: transparent;
      border-top: 1px solid color.$estimatorControlBgColor;

      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  .has-background-light-orange {
    background-color: #fcf9f4;
  }
}

.burger {
  @include mixins.desktops {
    display: block !important;
  }
}

.navbar-menu {
  z-index: 1;
  width: 100%;
  height: 100%;

  &.is-active {
    animation: nav-anim-open 0.2s ease-in;

    @include mixins.desktops {
      display: block !important;
    }
  }
}

.navbar-start {
  @include mixins.desktops {
    display: block;
    height: 120px;
  }

  @include mixins.until(1024px) {
    height: 280px;
  }
}

@keyframes nav-anim-open {
  0% {
    display: none;
    opacity: 0;
    max-height: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
    max-height: 396px;
  }
}
