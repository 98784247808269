@use "../color";

#public-estimator {
  background-color: color.$publicEstimatorBgColor;
  color: color.$publicEstimatorTextColor;

  .iframe-wrapper {
    position: relative;
    margin: auto;
    height: 100vh;
  }

  .hidden-public-estimator {
    display: none;
  }

  .has-text-orange {
    color: color.$primaryTextColor;
  }

  .select {
    &:not(.is-multiple) {
      &:not(.is-loading) {
        &::after {
          border-color: color.$selectInputBorderColor;
          right: 1.125em;
          z-index: 4;
        }
      }
    }

    select {
      &:focus {
        border-color: color.$selectInputBorderColor;
        box-shadow: 0 0 0 0 color.$selectInputBorderColor;
      }

      &:active {
        border-color: color.$selectInputBorderColor;
        box-shadow: 0 0 0 0 color.$selectInputBorderColor;
      }
    }

    select.is-focused {
      border-color: color.$selectInputBorderColor;
      box-shadow: 0 0 0 0 color.$selectInputBorderColor;
    }

    select.is-active {
      border-color: color.$selectInputBorderColor;
      box-shadow: 0 0 0 0 color.$selectInputBorderColor;
    }
  }

  .input {
    &:focus {
      border-color: color.$selectInputBorderColor;
      box-shadow: 0 0 0 0 color.$selectInputBorderColor;
    }

    &:active {
      border-color: color.$selectInputBorderColor;
      box-shadow: 0 0 0 0 color.$selectInputBorderColor;
    }
  }

  .is-focused.input {
    border-color: color.$selectInputBorderColor;
    box-shadow: 0 0 0 0 color.$selectInputBorderColor;
  }

  .is-active.input {
    border-color: color.$selectInputBorderColor;
    box-shadow: 0 0 0 0 color.$selectInputBorderColor;
  }
}
