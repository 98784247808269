@use "variables";

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

body {
  min-height: 100%;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.loading-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loading-spinner::after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid variables.$primary;
  border-color: variables.$primary transparent variables.$primary transparent;
  animation: loading-spinner 1.2s linear infinite;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hoverable {
  cursor: pointer;
}

.is-full-height {
  height: 100%;
}
