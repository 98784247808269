:root {
  --slider-bg-track-color: #c7c3c1;
  --slider-primary-bar-color: #313131;

  --slider-bar-height: 12px;
  --slider-track-border-radius: 10px;
  --slider-thumb-size: 20px;
}

/* Slider */

.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  margin-top: 1rem;
}

.SliderTrack {
  background-color: var(--slider-bg-track-color);
  position: relative;
  flex-grow: 1;
  border-radius: var(--slider-track-border-radius);
  height: var(--slider-bar-height);
}

.SliderRange {
  position: absolute;
  background-color: var(--slider-primary-bar-color);
  border-radius: var(--slider-track-border-radius);
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 0 0 3px black;
  border-radius: var(--slider-track-border-radius);
}

.SliderThumb:focus {
  outline: none;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
}
/* Tooltip */

.TooltipContent {
  width: max-content;
  max-width: 300px;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1.5;
  color: white;
  background-color: #444;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
}

.TooltipArrow {
  fill: #444;
}
