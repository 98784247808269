@charset "utf-8";
@use "variables";

$primary: variables.$primary;
$success: variables.$success;
$warning: variables.$warning;
$grey: variables.$grey;
$input-focus-border-color: variables.$input-focus-border-color;
$input-focus-color: variables.$input-focus-color;
$steps-thin-divider-size: 60px;
$steps-default-color: variables.$warning;
$family-primary: variables.$family-primary;
$family-secondary: variables.$family-poppins;

@import "../node_modules/bulma-responsive-tables/bulma-responsive-tables.scss";
@import "../node_modules/bulma/bulma.sass";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://kit.fontawesome.com/313dc4a437.js");
@import url("./components/contractForm/phases/StepsProgressBar.scss");
@import url("./pages/ContractForm/ContractForm.scss");
@import url("./pages/Dashboard/Dashboard.scss");
@import url("./pages/Contracts/Contracts.scss");
@import url("./pages/Estimator/Estimator.scss");
@import url("./styles/PublicEstimator.scss");
@import url("./pages/Landing/Landing.scss");
@import url("./pages/FAQ/FAQ.scss");
@import url("./pages/Documentation/Documentation.scss");
@import url("./layouts/PageLayout/PageLayout.scss");
@import url("./components/Sidebar.scss");
@import url("./global.scss");
@import url("./components/collapsiblePanel/styles.scss");
