@use "../../mixins";
@use "../../color";

#savvly-dashboard {
  .is-mobile-navbar {
    @include mixins.desktops {
      flex-direction: column;
    }
  }

  .is-fullwidth {
    width: 100%;
  }

  .tabs.is-toggle li.is-active a {
    background-color: color.$primaryToggleButtonBgColor;
    border-color: color.$primaryToggleButtonBgColor;
  }
}

.is-desktop {
  flex-direction: row;
  width: 100%;
}

.is-desktop > {
  :first-child {
    width: 15%;
  }

  :last-child {
    width: 100%;
  }
}

select option:checked {
  background: color.$primaryButtonBgColor -webkit-linear-gradient(bottom, color.$primaryButtonBgColor 0%, color.$primaryButtonBgColor
        100%);
  color: color.$selectOptionChecked;
}
